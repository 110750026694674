


exports.onInitialClientRender = function () {
    const utms = window.location.search;
    if (utms) {
      document.querySelectorAll("a").forEach((a) => {
        console.log(a)
        const current = a.getAttribute("href");
        const urlStipped = current.slice(0, current.lastIndexOf("/"));
        const url = urlStipped + utms;
        a.setAttribute("href", url);
      });
    }
  };